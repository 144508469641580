
:root {
    --amplify-fonts-default-static: 'Roboto', sans-serif;
    --amplify-fonts-default-variable: 'Roboto', sans-serif;
    --amplify-components-tabs-background-color: #fffdfd;
    --amplify-components-tabs-border-color: #2771D6;
    --amplify-components-tabs-item-active-border-color: #2771D6;

    --amplify-components-button-primary-background-color: #2771D6;
    --amplify-components-button-primary-hover-background-color: #18488c;
    --amplify-components-button-hover-background-color: #d9e6f9;
    --amplify-components-button-hover-border-color: #2771D6;
    --amplify-components-button-hover-color: #2771D6;

    --amplify-components-button-link-hover-background-color: #d9e6f9;
    --amplify-components-button-link-hover-color: #2771D6;

    --amplify-components-authenticator-router-box-shadow: 0px;
    --amplify-components-authenticator-router-border-radius: 10px;

    --amplify-components-card-border-radius: 10px;

    --amplify-components-card-border-radius: 5rem;
    --amplify-colors-brand-primary-100: #78A5E4;
    --amplify-colors-font-interactive: #000000;
    --amplify-components-tabs-item-active-color: #2771D6;
    --amplify-components-tabs-item-focus-color: #2771D6;
    --amplify-components-tabs-item-hover-color: #2771D6;
}